<template>
  <div style="width: 100%; height: 100%" class="big-video">
    <RtpPlayer
      :videoList="videoList"
      :speakVehicle="speakVehicle"
      :videoGrid="1" :displayNum="1"
      :videoCloseTime="realTimeConfig.videoCloseTime"
      :showParameter="false"
      @playStart="onPlayStart"
      @playEnd="onPlayEnd"
      @speakEnd="onSpeakEnd"
      :Z="Z"
    ></RtpPlayer>
    <div class="mask-box">
      <div class="mask-box-top">
        <div>{{ monitorDetails.plate }} - {{ channelList[channel] }}</div>
        <div style="display: flex">
          <div class="mask-box-small" @click="handleGo" style="cursor: pointer">
            <img
              src="../../../../assets/images/bigScreen/riskVideo/video-back.png"
              style="height: 0.2rem"
              alt=""
              srcset=""
            />
          </div>
          <div
            class="mask-box-small"
            style="width: 300px"
            v-if="monitorDetails.alarmRecordList && monitorDetails.alarmRecordList.length"
          >
            <img
              src="../../../../assets/images/bigScreen/riskVideo/video-message.png"
              style="height: 0.2rem; margin-right: 4px"
              alt=""
              srcset=""
            /><marquee scrollamount="3">
              {{
                monitorDetails.alarmRecordList &&
                monitorDetails.alarmRecordList.length
                  ? monitorDetails.alarmRecordList.toString()
                  : ""
              }}
            </marquee>
          </div>
          <div class="mask-box-small">
            报警次数: {{ monitorDetails.dayAlarmCount }}
          </div>
        </div>
      </div>
      <div class="mask-box-under">
        <div class="mask-box-small">
          <i
            class="el-icon-location-outline"
            style="color: #fff; font-size: 0.24rem; margin-right: 4px"
          ></i>
          当前定位：{{ monitorDetails.address }}
        </div>
        <div class="mask-box-small">
          工作时长：{{ monitorDetails.workingTime }}小时
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RtpPlayer from "@/views/RealTimePositioning/RtpPlayer.vue";
import { getTreeNodeId } from "@/utils/treeHelper";
export default {
  props: {
    isSpeak: {
      type: Boolean,
      default: false,
    },
    monitorDetails: {
      type: Object,
      default: () => {
        alarmRecordList: [];
      },
    },
    channel: {
      type: [Number, String],
      default: null,
    },
  },
  watch: {
    isSpeak: {
      handler(v) {
        console.log(v);
      },
    },
  },
  components: { RtpPlayer },
  computed: {
    ...mapState(["userConfig", "pathVehicle"]),
    realTimeConfig() {
      return this.userConfig.realTimeConfig;
    },
    speakVehicle() {
      const { isSpeak, monitorDetails } = this;
      if (!isSpeak) return null;

      // 终端号
      const device = monitorDetails.terminalNo;
      // 设备协议类型 1: GF-1078, GB-1078; 2: GA系列
      const protocolType = monitorDetails.terminalType.indexOf("GA-") < 0
        ? 1
        : 2;
      // const protocolType = ["GF-1078", "GB-1078"].includes(
      //   monitorDetails.terminalType
      // )
      //   ? 1
      //   : 2;
      // 特殊协议 0: 不处理; 1: 粤标
      const SpecialSign = 0;
      // 设备名称
      const plate = monitorDetails.plate;
      // 车辆Id
      const vehicleId = monitorDetails.vehicleId;
      // 车组Id
      const groupId = monitorDetails.groupId;

      return {
        device,
        protocolType,
        SpecialSign,
        plate,
        vehicleId,
        groupId,
      };
    },
  },
  watch: {
    monitorDetails: {
      handler(v) {
        this.handlePlayStart();
      }
    },
    channel: {
      handler(v) {
        this.handlePlayStart();
      }
    },
  },
  created() {
    this.isAlarm = this.$route.query.isAlarm ? true : false;
  },
  data() {
    return {
      videoList: [],
      currentSpeak: null,
      Z: 2,
      channelList: ["", "右摄像头", "后摄像头", "左摄像头", "前摄像头", '驾驶室内摄像头','卷扬摄像头'],
      isAlarm: false,
    };
  },
  methods: {
    handlePlayStart() {
        const { channel, monitorDetails } = this;
        const key = getTreeNodeId(
          monitorDetails.groupId,
          monitorDetails.vehicleId,
          channel
        );
        // 设备协议类型 1: GF-1078, GB-1078; 2: GA系列
        // const protocolType = ["GF-1078", "GB-1078"].includes(
        //   monitorDetails.terminalType
        // )
        //   ? 1
        //   : 2;
        
      const protocolType = monitorDetails.terminalType.indexOf("GA-") < 0
        ? 1
        : 2;
        this.videoList = [
          {
            key,
            channel,
            device: monitorDetails.terminalNo,
            protocolType,
            SpecialSign: 0,
            plate: monitorDetails.plate,
            vehicleId: monitorDetails.vehicleId,
            groupId: monitorDetails.groupId,
          },
        ];
    },
    onPlayStart(channelObj) {
      const { videoList } = this;
      if (!videoList.some((obj) => obj.key === channelObj.key)) {
        videoList.push(channelObj);
      }
    },
    onPlayEnd(channelObj) {
      const { videoList } = this;
      this.videoList = videoList.filter((obj) => obj.key !== channelObj.key);

      if (!this.videoList.length && channelObj.countdownEnd) {
        this.visible = true;
        this.videoPlate = null;
        setTimeout(() => {
          this.visible = false;
        }, 4000);
      }
    },
    onSpeakEnd() {
      this.currentVehicle = null;
    },
    handleGo() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss" >
.big-video {
  position: relative;
  .player {
    border: none !important;
  }
  .mask-box {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url("../../../../assets/images/bigScreen/riskVideo/video-mask.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 10;
    > div {
      position: absolute;
      left: 1.2%;
      right: 1.2%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-top {
      top: 0;
      height: 5%;
      color: #ffffff;
      font-size: 0.24rem;
    }
    &-under {
      bottom: 4%;
    }
    &-small {
      display: flex;
      align-items: center;
      padding: 0 0.16rem;
      height: 0.32rem;
      background-color: rgba(38, 38, 38, 0.8);
      border-radius: 0.16rem;
      margin-left: 0.32rem;
      color: #ffffff;
      font-size: 0.16rem;
    }
  }
}
</style>